import Papa from "papaparse";

export function saveParseCsv(FileObj, doesHeaderRowExist, callback) {
	// We need an array of objects, but if the user had no header row
	// then papaparse will be giving us an array of arrays. And so we read
	// the file first
	FileObj.text().then(text => {
		let fileForParsing = text;
		// Then we duplicate the first line if there is no header row
		// so we can generate our own header row.
		let headerFunc = header => (header === "query" ? "keyword" : header);
		if (!doesHeaderRowExist) {
			const fileLines = text.split("\n");
			const firstLine = fileLines[0];

			fileForParsing = `${firstLine}\n${text}`;
			headerFunc = prepareEmptyHeader;
		}

		// This allows us to then
		const parsedData = Papa.parse(fileForParsing, {
			header: true,
			transformHeader: headerFunc,
			skipEmptyLines: true,
			dynamicTyping: true
		});

		// Run callback
		callback(parsedData);
	});
}

export function prepareEmptyHeader(header, index) {
	return `column${index}`;
}
