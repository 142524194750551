// getSearchWorker.js

import ToolWorker from "src/services/tools/individualTools/findCannibalisation/findingCannabalisation.worker";

let toolWorker;

export default function getFindCannabalisationWorker() {
	if (!toolWorker) {
		toolWorker = new ToolWorker();
	}
	return toolWorker;
}
