import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import t from "tcomb-form";
import templates from "tcomb-form-templates-bulma";

import Helmet from "react-helmet";
import Layout from "src/layouts/LayoutWhiteHeader";
import SEO from "src/components/generic/SEO/SEO";

import { runBackgroundTaskSaga } from "src/services/tools/toolsActions";
import { arraysEqual, multipleUpdates, safeLowerString } from "src/utils/utils";
import { saveParseCsv } from "src/services/tools/toolsUtils";

import getFindCannabalisationWorker from "src/services/tools/individualTools/findCannibalisation/getFindingCannabalisationWorker";
// import getSearchWorker from "src/services/tools/individualTools/getSearchWorker";
import { buildURI } from "src/services/csv/csv";

// import { read_csv } from "danfojs/src/index";

// TCOMB SETUP
const { Form } = t.form;
t.form.Form.templates = templates;

// Validators
const MyFile = t.irreducible("File", function(x) {
	return x instanceof File;
});

const isItACsv = file => {
	if (file) {
		return /\.(csv|tsv)/.test(file.name);
	}
	return true;
};

const findingCannaTComb = t.struct({
	name: t.String,
	doesHeaderRowExist: t.Bool,
	file: t.maybe(t.refinement(MyFile, isItACsv)),
	brandRegex: t.String,
	columnKeyword: t.String,
	columnURL: t.String,
	columnDate: t.String,
	columnImpressions: t.String,
	columnClicks: t.String,
	columnRank: t.String
});

class FindingCannabalisationTool extends Component {
	static propTypes = {
		actions: PropTypes.shape({
			runBackgroundTaskSaga: PropTypes.func.isRequired
		}).isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			formValues: {
				doesHeaderRowExist: false
			},
			isProcessing: false,
			// Is form working (currently this would only be file CSV.)
			formwideErrorMsg: "",
			downloadUrl: "",
			// The parsed version of the user data.
			parsedData: null,
			tCombOptions: {
				auto: "placeholders",
				fields: {
					name: {
						error: "Please enter a website",
						attrs: {
							placeholder: "Website name"
						},
						help: "(We'll put it in the filename)."
					},
					file: {
						type: "file",
						help: "Please choose a search console file.",
						error:
							"This file must a delimited file i.e.: .csv or .tsv."
					},
					brandRegex: {
						attrs: {
							placeholder: "A regex which will match your brand.",
							help:
								"if you don't know regex just write your brand name"
						},
						config: {
							addonAfter: (
								<h2 className="is-size-6 m-t-m">
									Select the columns once you&apos;ve picked a
									CSV
								</h2>
							)
						}
					},
					columnKeyword: {
						factory: t.form.Select,
						attrs: {
							placeholder: "Which column contains keywords?"
						},
						help: "In which column is your keyword data?",
						options: [],
						nullOption: {
							value: "",
							text: `Select your keyword column.`
						},
						addonBefore: "DOMDOMDOMD"
					},
					columnURL: {
						factory: t.form.Select,
						help: "In which column is your URL data?",
						attrs: {
							placeholder: "Which column contains URLs?",
							"data-testid": "urlColSelect"
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your url column.`
						}
					},
					columnDate: {
						factory: t.form.Select,
						help: "In which column are your dates?",
						attrs: {
							placeholder: "Which column contains dates?",
							"data-testid": "dateColSelect"
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your date column.`
						}
					},
					columnImpressions: {
						factory: t.form.Select,
						help: "In which column are impressions?",
						attrs: {
							placeholder: "Which column contains impressions?",
							"data-testid": "impressionsColSelect"
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your impressions column.`
						}
					},
					columnClicks: {
						factory: t.form.Select,
						help: "In which column are clicks?",
						attrs: {
							placeholder: "Which column contains clicks?",
							"data-testid": "clickColSelect"
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your clicks column.`
						}
					},
					columnRank: {
						factory: t.form.Select,
						help: "In which column is rank?",
						attrs: {
							placeholder: "Which column contains rank?",
							"data-testid": "clickRankSelect"
						},
						options: [],
						nullOption: {
							value: "",
							text: `Select your rank column.`
						}
					}
				}
			}
		};
	}

	saveDataToState = data => {
		if (data.errors.length > 0) {
			this.setState({
				formwideErrorMsg: data.errors.join("\n")
			});
		} else {
			this.setState({
				parsedData: data.data
			});

			// Set-up form options
			this.setUpFormBasedOnData(data.data);
		}
	};

	setUpFormBasedOnData = csvData => {
		// The first part of this is giving the correct column
		// options

		// Get the first row for making headers
		const firstRow = csvData[0];

		// Get the column headers to populate in the form
		const dataColumnOptions = Object.keys(firstRow).map((cell, i) => {
			return {
				value: cell,
				text: `Column ${i} - ${cell}`
			};
		});

		const newTCombOptions = multipleUpdates(
			[
				{
					path: ["fields", "columnKeyword", "options"],
					value: dataColumnOptions
				},
				{
					path: ["fields", "columnURL", "options"],
					value: dataColumnOptions
				},
				{
					path: ["fields", "columnDate", "options"],
					value: dataColumnOptions
				},
				{
					path: ["fields", "columnImpressions", "options"],
					value: dataColumnOptions
				},
				{
					path: ["fields", "columnClicks", "options"],
					value: dataColumnOptions
				},
				{
					path: ["fields", "columnRank", "options"],
					value: dataColumnOptions
				}
			],
			this.state.tCombOptions
		);

		this.setState({
			tCombOptions: newTCombOptions
		});
	};

	// eslint-disable-next-line consistent-return
	onFormChange = (value, path) => {
		// Component may not exist if we're deleting elements of the ruleset
		// in which case skip validation.
		const component = this.findingCannaRef.getComponent(path);

		if (component) {
			component.validate();
		} else {
			return true;
		}

		if (arraysEqual(path, ["file"])) {
			saveParseCsv(
				value.file,
				this.state.formValues.doesHeaderRowExist,
				this.saveDataToState
			);
		}

		this.setState({
			formValues: value
		});
	};

	onFormSubmit = e => {
		e.preventDefault();

		// This is tcomb fun that gets the values from
		// the form
		const value = this.findingCannaRef.getValue();

		if (value) {
			this.setState({
				isProcessing: true
			});

			getFindCannabalisationWorker()
				.findCannabalisation(
					this.state.parsedData,
					value.brandRegex,
					value.columnKeyword,
					value.columnURL,
					value.columnDate,
					value.columnImpressions,
					value.columnClicks,
					value.columnRank
				)
				.then(results => {
					this.setState({
						isProcessing: false
					});

					const uri = buildURI(results, true, null, ",", '"');

					this.setState({
						downloadUrl: uri
					});
				});
		}
	};

	render() {
		let downloadButton;
		if (this.state.downloadUrl) {
			const safeSite = safeLowerString(this.state.formValues.name);
			downloadButton = (
				<a
					download={`cannabalisation_${safeSite}.csv`}
					href={this.state.downloadUrl}
					className="m-b-m button is-primary"
					type="button"
				>
					Download CSV
				</a>
			);
		}

		let form;
		if (this.state.isProcessing) {
			form = <div className="loaderLarge clear-both" />;
		} else {
			form = (
				<React.Fragment>
					<form
						id="find"
						className={`toolBox`}
						onSubmit={this.onFormSubmit}
						data-testid="dataForm"
					>
						{downloadButton}
						<h2 className="is-size-6 p-b-md">
							Find cannabalisation
						</h2>
						<Form
							ref={ref => {
								this.findingCannaRef = ref;
							}}
							type={findingCannaTComb}
							options={this.state.tCombOptions}
							value={this.state.formValues}
							onChange={this.onFormChange}
						/>
						<div className="field is-grouped bottomOfForm">
							<button
								type="submit"
								className="button is-primary control m-t-md"
							>
								Submit
							</button>
						</div>
					</form>
				</React.Fragment>
			);
		}

		let errorMessage;
		if (this.state.formwideErrorMsg) {
			errorMessage = (
				<div className="is-error message">
					<p className="message-body">
						{this.state.formwideErrorMsg}
					</p>
				</div>
			);
		}

		return (
			<Layout>
				<Helmet title="Finding cannibalisation" />
				<SEO />
				<section className="container m-t-xl m-b-xl">
					<h1>Cannabalisation finder</h1>
					<div className="columns">
						<div className="column">
							<p>
								This tool will help you find cannibalisation
								with your search console data.
							</p>
							<h3>How to use it?</h3>
							<p>
								You&apos;ll need to get a set of search console
								data with the following columns.
							</p>
							<ul>
								<li>Date</li>
								<li>Path/page</li>
								<li>Query</li>
								<li>Clicks</li>
								<li>Impressions</li>
								<li>Rank</li>
							</ul>
							<p>When you&apos;ve got that:</p>
							<ol>
								<li>
									Type in the website name (or something
									unique, we&apos;ll use it to generate the
									filename).
								</li>
								<li>Choose if the file has headers.</li>
								<li>Select the search console file.</li>
								<li>
									Add a regex to match your brand. If you
									don&apos;t know regex just type your brand.
								</li>
								<li>Pick the correct columns.</li>
								<li>Hit submit and wait!</li>
							</ol>
							<h3>How does it work?</h3>
							<p>
								If you want to read about how it works there is
								a blog post which describes the process here.
							</p>
							<p>
								We ask for the brand regex, because
								cannabalisation on branded terms isn&apos;t
								really a thing so they get filtered out.
							</p>
							<p>
								It runs entirely in your browser, using JS to
								run the data so it probably won&apos;t be able
								to run on giant files, but you never know...
							</p>
						</div>

						<div className="column">
							{errorMessage}
							{form}
						</div>
					</div>
				</section>
			</Layout>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators({ runBackgroundTaskSaga }, dispatch)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(FindingCannabalisationTool);
